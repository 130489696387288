import React from 'react';
import { Layout } from '../components';

const NotFoundPage = () => (
  <Layout>
    <div className="container">
      <section>
        <h1>Siden eksiterer ikke</h1>
        <p>Siden som du forsøger at se findes ikke.</p>
      </section>
    </div>
  </Layout>
);

export default NotFoundPage;
